import axios from 'axios'
import Qs from 'qs'
import { Dialog } from 'vant'
import { getToken, removeToken, setToken } from '@/utils/auth'
import { platform } from '@/utils/index'
import Cache from '@/utils/cache'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 3000, // request timeout
  transformRequest: [data => {
    data = Qs.stringify(data)
    return data
  }],
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/vnd.duoduo.v1+json'
  }
})

// request
service.interceptors.request.use(
  config => {
    config.headers.Authorization = getToken()
    if (Cache.get('store_id')) {
      config.headers['Store-Id'] = Cache.get('store_id')
    }
    config.headers.Platform = platform()
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const { authorization } = response.headers
    const res = response.data
    if (authorization) {
      setToken(authorization)
    }
    // 返回的状态嘛不是 200
    if (res.code !== 200) {
      // 登录失效
      if (res.code === 401 || res.code === 402) {
        Cache.set('redirect', window.location.href)
        removeToken()
        window.location.href = '/login'
      } else {
        Dialog({
          title: '提示',
          message: res.message || 'Error'
        }).then(() => {})
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    Dialog({
      title: '提示',
      message: (error.message && error.message !== 'Network Error' ? error.message : '网络异常') || '网络错误'
    }).then(() => {})
    return Promise.reject(error)
  }
)

export default service
