/**
 * Created by JianJia.Zhou<jianjia.zhou> on 2023/11/22.
 */
import request from '@/utils/request'

export function create(data) {
  return request({
    url: `/payment/order/create`,
    method: 'post',
    data
  })
}

export function createNoProduct(data) {
  return request({
    url: `/payment/order/noproduct/create`,
    method: 'post',
    data
  })
}

export default {
  create,
  createNoProduct
}
