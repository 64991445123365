import Cache from './cache'

const TokenKey = 'auth_token'

export function getToken() {
  return Cache.get(TokenKey)
}

export function setToken(token) {
  return Cache.set(TokenKey, token)
}

export function removeToken() {
  return Cache.remove(TokenKey)
}
