import Vue from 'vue'
import Vant from 'vant'
import App from './App.vue'
import router from './router'
import store from './store'
import Api from './api'
import 'vant/lib/index.css'
import 'flex.css'
import Cache from '@/utils/cache'
import { platform } from '@/utils'

Vue.config.productionTip = false

const urlPath = window.location.pathname
const whiteUrl = ['/login', '/authorize', '/']
if (!Api.user.isLogin() && whiteUrl.indexOf(urlPath) === -1) {
  Cache.set('redirect', window.location.href)
  window.location.href = '/login'
}

Vue.use(Vant)
Vue.use({
  install(Vue, options) {
    Vue.prototype.$api = Api
    Vue.prototype.$platform = platform()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
