<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => {
      const appDom = document.querySelector('#app')
      const checkDom = document.createElement('span')
      checkDom.setAttribute('id', 'my_check_load_dom')
      checkDom.style.display = 'none'
      appDom.appendChild(checkDom)
    })
  }
}
</script>

<style lang="scss">
*{
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
