<template>
  <layout-view class="mch-container">
    <template v-if="!loading">
      <div
        class="store-wrapper"
        flex="main:center cross:center"
      >
        向 <div class="store-name">{{ storeInfo.name }}</div>付款
      </div>
      <div
        class="settlement-wrapper"
        :class="{'settlement-wrapper__shadow' : price > 0}"
      >
        <div class="settlement-wrapper__text">付款金额(元)</div>
        <div
          class="settlement-wrapper__input-box"
          flex="main:left cross:center"
        >
          <div
            class="input-box__text"
            felx="main:center cross:center"
          >&yen;</div>
          <input
            v-model="price"
            class="input-box__input"
            readonly
            placeholder="请输入金额"
          >
        </div>
        <!-- 备注  START   -->
        <div
          v-if="remark.length === 0"
          class="settlement-wrapper__remake"
          @click="remarkShow = !remarkShow"
        >添加备注</div>
        <div
          v-else
          class="settlement-wrapper__remake-text"
        >
          {{ remark }}
        </div>
        <van-dialog
          v-model="remarkShow"
          title="付款备注"
          show-cancel-button
        >
          <van-cell-group class="remark-input">
            <van-field
              v-model="remark"
              rows="4"
              autosize
              type="textarea"
              placeholder="输入备注"
            />
          </van-cell-group>
        </van-dialog>
      </div>
      <div
        v-if="remark.length > 0"
        class="edit-remake"
        @click="remarkShow = !remarkShow"
      >修改备注</div>
      <!-- 备注   END  -->
      <!-- LOGO     -->
      <div class="logo" />
      <van-number-keyboard
        :show="true"
        theme="custom"
        extra-key="."
        class="mch-keyboard"
        close-button-text="确定"
        @input="onInput"
        @delete="onDelete"
        @close="handleCreateOrder"
      />
    </template>
  </layout-view>
</template>

<script>
import LayoutView from '@/components/Layout'
import Cache from '@/utils/cache'
import WechatAPI from '@/utils/WechatAPI'
import AlipayAPI from '@/utils/AlipayAPI'
const IS_WECHAT = (/micromessenger/i).test(navigator.userAgent)
const IS_ALIPAY = (/AlipayClient/i).test(navigator.userAgent)
const IS_UNIONPAY = (/UnionPay/i).test(navigator.userAgent)

export default {
  name: 'MchView',
  components: { LayoutView },
  data() {
    return {
      remarkShow: false,
      price: '',
      remark: '',
      id: '',
      broadcast_device_number: '',
      storeInfo: {},
      loading: false,
      payId: ''
    }
  },
  computed: {
    payType() {
      if (IS_WECHAT) return 'wx_jsapi'
      if (IS_ALIPAY) return 'alipay_jsapi'
      if (IS_UNIONPAY) return 'upacp_quick'
      return ''
    }
  },
  created() {
    this.id = this.$route.params.id
    Cache.set('store_id', this.id)
    const broadcast_device_number = this.$route.query.broadcast_device_number
    if ((typeof broadcast_device_number !== 'undefined') && broadcast_device_number) {
      this.broadcast_device_number = broadcast_device_number
    }
    this.getInfo()
  },
  methods: {
    handleCreateOrder() {
      this.validation().then(() => {
        this.$toast.loading({ forbidClick: true, message: '创建订单...' })
        this.$api.order.createNoProduct({ order_fee: this.price, source: 12, broadcast_device_number: this.broadcast_device_number }).then(res => {
          this.$toast.clear()
          this.payId = res.data.pay_id
          this.handlePay(res.data.pay_id)
        }).catch(() => {
          this.$toast.clear()
        })
      }).catch(() => {
        console.log('-->data', 2)
      })
    },

    handlePay(payId) {
      this.$toast.loading({ forbidClick: true, message: '发起支付...' })
      this.$api.pay.payment(payId, { pay_type: this.payType }).then(res => {
        this.loading = false
        this.handlePayment(res.data)
      }).catch(() => {
        this.loading = false
      })
    },
    handlePayment(data) {
      switch (this.payType) {
        case 'wx_jsapi':
          this.wechatPayment(data.payInfo)
          break
        case 'alipay_jsapi':
          this.alipayPayment(data.payInfo)
          break
        case 'upacp_quick':
          break
      }
    },
    wechatPayment(config) {
      WechatAPI.payment(config, this.paySuccess, this.payCancel)
    },
    alipayPayment(config) {
      AlipayAPI.payment(config, this.paySuccess, this.payCancel)
    },
    paySuccess(data) {
      /**
       * 微信已经改了，支付会直接关闭，但是开启了点金计划会跳转到自定义页面
       * @url https://m.weicai720.cn/common/paySuccess/index
       */
      this.$toast.loading({ forbidClick: true, message: '支付结果查询中...' })
      const index = setInterval(() => {
        this.$api.pay.query(this.payId).then(res => {
          if (+res.data.status === 1) {
            this.price = ''
            clearInterval(index)
            this.$toast.clear()
            this.$dialog.alert({
              message: res ? '支付成功' : JSON.stringify(data)
            }).then(() => {
              if (IS_WECHAT) {
                WechatAPI.closeWindow()
              } else if (IS_ALIPAY) {
                AlipayAPI.closeWindow()
              }
            })
          }
        })
      }, 1000)
    },
    /**
     * 用户取消支付回调
     * @param data
     */
    payCancel(data) {
      if (this.broadcast_device_number) {
        this.$api.common.cancelBroadcast({ sbx_id: this.broadcast_device_number, msg: '用户取消支付' }).then(res => {

        }).catch(() => {
          console.log('device broadcast error')
        })
      }
    },

    onInput(str) {
      // 第一个输入必须为数字
      if (str === '.' && (!this.price || this.price.toString().indexOf('.') !== -1)) {
        return
      }
      // 第一个输入的数字是0
      const first = this.price.toString().substr(0, 1)
      if (first !== '' && +first === 0 && this.price.toString().indexOf('.') === -1) {
        // 第二个还是0不处理
        if (+str === 0) {
          return
        }
        // 第二个数字如果不是小数点，那直接以现在这个数字为首位
        if (+str > 0) {
          this.price = ''
        }
      }
      this.price = `${this.price}${str}`
      const arr = this.price.toString().split('.')
      // 输入限制整数最大不能超过10位
      if (arr[0].length > 10) {
        this.onDelete()
      }
      // 处理小数部分
      if (this.price.toString().indexOf('.') !== -1) {
        // 只能两位小数(分)，两位小数最后一位为0不处理
        const last = this.price.toString().substr(this.price.toString().length - 1, 1)
        if (arr[1].length > 2 || (arr[1].length === 2 && +last === 0)) {
          this.onDelete()
        }
      }
    },
    onDelete() {
      this.price = this.price.slice(0, this.price.toString().length - 1)
    },
    handleClickLeft() {
      window.history.go(-1)
    },
    getInfo() {
      this.loading = true
      this.$toast.loading({ forbidClick: true, message: '数据获取中...' })
      this.$api.store.info(this.id).then(res => {
        this.$toast.clear()
        this.loading = false
        this.storeInfo = res.data
      }).catch(() => {
        this.$toast.clear()
        this.loading = false
        this.$router.push('/error?error=非法访问，请核对')
      })
    },
    validation() {
      return new Promise((resolve, reject) => {
        if (!this.price || this.price <= 0) {
          this.$toast('请输入金额')
          // eslint-disable-next-line prefer-promise-reject-errors
          return reject()
        }
        if (this.price > 999) {
          this.$dialog.confirm({
            title: '请核对金额',
            message: `确定向商家付款：&yen;${this.price}？`
          }).then(() => {
            return resolve()
          }).catch(() => {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject()
          })
        } else {
          return resolve()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$color: #E83A0C;
$color-dp: #ce330b;
.mch-container {
  background: #fafafa;
  min-height: 100vh;
  padding-top: .01rem;
  .store-wrapper{
    font-weight: 600;
    font-size: 1.6rem;
    margin: 1.4rem 0 1.2rem;
    .store-name{
      color: $color;
    }
  }

  .settlement-wrapper{
    background: #fff;
    border: 0.01rem solid #ccc;
    border-radius: 6px;
    margin: 0 0.8rem;
    padding: .6rem .8rem;
    transition: .5s;
    &__shadow{
      box-shadow: 0 0 10px rgba(0,0,0,.08);
    }
    &__text{
      text-align: left;
      font-size: 0.8rem;
      color: #333;
    }
    &__input-box{
      border-bottom: 0.01rem solid #ccc;
      padding: 1.2rem 0;
      color: #000;
      .input-box{
        &__text{
          font-size: 2.2rem;
          font-weight: 400;
        }
        &__input{
          border: none;
          width: 100%;
          margin-left: .4rem;
          font-size: 2.4rem;
          padding: .4rem 0;
        }
      }
    }
    &__remake{
      color: #FF5556;
      text-align: left;
      margin-top: .6rem;
      font-size: 0.8rem;
      &-text{
        text-align: left;
        color: #666;
        margin-top: .6rem;
      }
    }
  }

  .edit-remake{
    color: #999;
    font-size: 0.8rem;
    text-align: right;
    padding: .8rem 1rem 0;
  }

  .logo{
    background: url("@/assets/logo-hor.png") no-repeat center;
    width: 100%;
    height: 40px;
    background-size: 36%;
    position: fixed;
    bottom: 40.6vh;
  }

  /*键盘*/
  .mch-keyboard::v-deep{
    padding-bottom: 0;

    .van-number-keyboard__body{
      padding: 0;
    }
    .van-number-keyboard__keys{
      .van-key__wrapper{
        &:nth-child(3n + 1){
          .van-key{
            border-left: 0.01rem solid #ccc !important;
          }
        }
        &:nth-last-child(2){
          flex-basis: 66.3%;
        }
      }
    }
    .van-key--blue{
      background: $color;
      &.van-key--active{
        background: $color-dp;
      }
    }
    .van-key__wrapper{
      padding: 0;
      .van-key{
        border-radius: 0;
        border: 0.01rem solid #ccc;
        border-left: 0;
        border-bottom: 0;
        //height: 5.2rem !important;
        height: 10.2vh !important;
        &--large{
          height: 100% !important;
          width: 100%;
        }
      }
    }
    .van-number-keyboard__sidebar{
      .van-key{
        border-left: 0 !important;
        &--delete{
          border-bottom: 0;
        }
        &--blue{
          border-top: 0;
        }
      }
    }
  }
}
</style>
