/**
 * Created by JianJia.Zhou<jianjia.zhou> on 2023/11/22.
 */
import request from '@/utils/request'

export function update(id) {
  return request({
    url: `/payment/${id}/update`,
    method: 'post'
  })
}

export function info(id) {
  return request({
    url: `/payment/${id}/info`,
    method: 'get'
  })
}

export function payment(id, data) {
  return request({
    url: `/payment/${id}`,
    method: 'post',
    data
  })
}

export function query(id) {
  return request({
    url: `/payment/${id}/query`,
    method: 'post'
  })
}

export function create(data) {
  return request({
    url: `/payment/create`,
    method: 'post',
    data
  })
}

export function guikehuiSendSms(mobile) {
  return request({
    url: `/payment/guikehui/sendSms`,
    method: 'post',
    data: { mobile }
  })
}

export function guikehuiQueryMember(mobile) {
  return request({
    url: `/payment/guikehui/queryMember`,
    method: 'post',
    data: { mobile }
  })
}

export default {
  update,
  info,
  payment,
  query,
  create,
  guikehuiSendSms,
  guikehuiQueryMember
}
