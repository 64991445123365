<template>
  <layout-view class="wrapper">
    <van-nav-bar
      v-if="!isInsideApp"
      title="收银台"
      left-text="返回"
      left-arrow
      @click-left="handleClickLeft"
    />
    <template v-if="!pageLoading">
      <div
        class="pay-info"
        flex="dir:top cross:center main:center"
      >
        <div class="pay-info__item">支付金额</div>
        <div class="pay-info__item pay-info__price">&yen;{{ parseFloat(payInfo.real_fee).toFixed(2) }}</div>
        <div class="pay-info__item pay-info__store">{{ payInfo.store.name }}</div>
      </div>

      <div class="pay-types">
        <van-radio-group v-model="payType">
          <van-cell-group>
            <van-cell
              v-for="item in payTypes"
              :key="item.value"
              title-class="pay-type__title"
              @click="handleSelectPayType(item)"
            >
              <template #title>
                <span v-html="item.name" />
                <!--                <span-->
                <!--                  v-else-->
                <!--                  v-html="`${item.name}${guikehuiMember.balanceUsable?`(&yen;${guikehuiMember.balanceUsable})`:''}`"-->
                <!--                />-->
              </template>
              <template #icon>
                <van-icon
                  :name="item.icon"
                  :color="item.color"
                  size="22px"
                />
              </template>
              <template #right-icon>
                <van-radio
                  :disabled="item.disabled"
                  :name="item.value"
                  checked-color="#0AA650"
                />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-field
          v-if="payType === 'authpay'"
          v-model="authCode"
          label="授权码"
          placeholder="请输入授权码"
        />
        <template v-if="payType === 'guikehui'">
          <van-field
            ref="guikehuiInput"
            v-model="guikehuiForm.mobile"
            label="手机号"
            placeholder="手机号"
            maxlength="11"
            type="number"
          />
          <van-field
            v-model="guikehuiForm.code"
            label="验证码"
            placeholder="验证码"
          >
            <template #button>
              <van-button
                size="small"
                type="primary"
                :disabled="guikehuiSmsCode.downtime < 60 || guikehuiForm.mobile.length < 11"
                style="width: 90px;"
                @click="handleSendGuikehuiSms"
              >{{ guikehuiSmsCode.downtime < 60 ? `${guikehuiSmsCode.downtime}s` : '发送验证码' }}</van-button>
            </template>
          </van-field>

        </template>
      </div>

      <div class="btn-group">
        <van-button
          v-if="payInfo.status === 0"
          type="primary"
          size="large"
          block
          :loading="loading"
          :disabled="loading"
          @click="handlePay"
        >立即付款</van-button>
        <van-button
          v-else
          type="primary"
          size="large"
          block
          @click="handleRedirect"
        >跳转详情</van-button>
      </div>
    </template>
  </layout-view>
</template>

<script>
import { getQueryParams } from '@/utils'
import WechatAPI from '@/utils/WechatAPI'
import AlipayAPI from '@/utils/AlipayAPI'
import LayoutView from '@/components/Layout'
const IS_WECHAT = (/micromessenger/i).test(navigator.userAgent)
const IS_ALIPAY = (/AlipayClient/i).test(navigator.userAgent)
const IS_UNIONPAY = (/UnionPay/i).test(navigator.userAgent)
const IS_LOCAL = process.env.VUE_APP_LOCAL === 'local'

export default {
  name: 'PaymentView',
  components: { LayoutView },
  data() {
    return {
      pageLoading: true,
      payType: 'wx_jsapi',
      loading: false,
      disabledOverage: false,
      payId: 0,
      payInfo: {},
      settings: { show_authpay: 0 },
      isWechat: IS_WECHAT,
      isAlipay: IS_ALIPAY,
      isUnionPay: IS_UNIONPAY,
      authCode: '',
      payTypes: [],
      redirect: IS_WECHAT ? process.env.STORE_HOME_URL : '',
      guikehuiForm: {
        mobile: '',
        code: '',
        verifyNo: '2024072909045254794'
      },
      guikehuiSmsCode: {
        interval: null,
        downtime: 60
      },
      guikehuiMember: {}
    }
  },
  computed: {
    isInsideApp() {
      return this.isWechat || this.isAlipay || process.env.NODE_ENV === 'development' || IS_LOCAL
    }
  },
  watch: {
    payType(val) {
      if (val === 'guikehui') {
        this.$nextTick(() => {
          this.$refs.guikehuiInput.focus()
        })
      }
    }
  },
  async created() {
    const { redirect } = this.$route.query
    if (redirect) this.redirect = decodeURIComponent(redirect)
    if (this.isInsideApp) {
      this.disabledOverage = getQueryParams('disable_overage')
      this.payId = this.$route.params.id
      if (this.payId) {
        await this.update()
        this.getInfo()
      } else {
        this.$dialog.alert({
          message: '非法操作'
        }).catch(() => {
          window.history.go(-1)
        })
      }
    }
  },
  methods: {
    handlePay() {
      const params = { pay_type: this.payType, auth_code: this.authCode }
      if (!this.isWechat && this.payType === 'wx_jsapi') {
        this.$toast('请到微信里面完成支付！')
        return
      }

      if (this.payType === 'authpay' && !this.authCode) {
        this.$toast('请填写正确的授权码！')
        return
      }

      if (this.payType === 'guikehui' && (!this.guikehuiForm.mobile || !this.guikehuiForm.code)) {
        if (!/^1\d{10}/.test(this.guikehuiForm.mobile)) {
          this.guikehuiMember = {}
          this.guikehuiForm.code = ''
          this.guikehuiForm.verifyNo = ''
          this.$toast('请填写正确的手机号')
          return
        }
        if (!this.guikehuiForm.code) {
          this.$toast('请填写贵客荟验证码！')
          return
        }
      }
      if (this.payType === 'authpay') {
        params.auth_code = this.authCode
      }
      if (this.payType === 'guikehui') {
        params.guikehuiForm = this.guikehuiForm
      }

      if (this.loading) return

      this.loading = true
      this.$api.pay.payment(this.payId, params).then(res => {
        this.handlePayment(res.data)
        // loading = false不在这里进行赋值，为了防止支付弹窗还没有弹起，点击按钮触发系统到盛付通下二次单导致之前的单号失效
        // 但是用户实际支付的是第一个单号，盛付通回调的时候会出现找不到支付单号的情况
        // 所以改到支付里面去赋值
      }).catch(() => {
        this.loading = false
      })
    },
    handlePayment(data) {
      switch (this.payType) {
        case 'wx_jsapi':
          this.wechatPayment(data.payInfo)
          break
        case 'alipay_jsapi':
          this.alipayPayment(data.payInfo)
          break
        case 'upacp_quick':
          // todo 银联支付
          break
        case 'overage':
          this.overagePayment(data)
          break
        case 'authpay':
          this.authPayPayment(data)
          break
        case 'guikehui':
          this.paySuccess(data)
          break
      }
    },

    wechatPayment(config) {
      WechatAPI.payment(config, this.paySuccess, this.payCancel, this.payError)
    },
    alipayPayment(config) {
      AlipayAPI.payment(config, this.paySuccess, this.payCancel, this.payError)
    },
    overagePayment(config) {
      // 混合支付
      if (config.mix_pay) {
        this.payType = 'wx_jsapi'
        if (this.isWechat) this.payType = 'wx_jsapi'
        if (this.isAlipay) this.payType = 'alipay_jsapi'
        this.loading = false
        this.handlePay()
      } else {
        this.paySuccess()
      }
    },
    authPayPayment(config) {
      this.paySuccess()
    },
    paySuccess(data) {
      /**
       * 微信已经改了，支付会直接关闭，但是开启了点金计划会跳转到自定义页面
       * @url https://m.weicai720.cn/common/paySuccess/index
       */
      this.loading = false
      this.$toast.loading({ forbidClick: true, message: '支付结果查询中...' })
      const index = setInterval(() => {
        this.$api.pay.query(this.payId).then(res => {
          if (+res.data.status === 1) {
            clearInterval(index)
            this.$toast.clear()
            this.$dialog.alert({
              message: res ? '支付成功' : JSON.stringify(data)
            }).then(() => {
              this.handleRedirect()
            })
          }
        })
      }, 1000)
    },
    payCancel() {
      this.loading = false
    },
    payError() {
      this.loading = false
    },
    handleRedirect() {
      if (this.isWechat) {
        WechatAPI.closeWindow()
      } else if (this.isAlipay) {
        AlipayAPI.closeWindow()
      } else if (this.redirect) {
        window.location.href = this.redirect
      }
    },
    handleClickLeft() {
      window.history.go(-1)
    },
    handleSelectPayType(item) {
      if (item.disabled) return
      this.payType = item.value
    },
    async handleSendGuikehuiSms() {
      if (this.guikehuiForm.mobile.length < 11) {
        this.$toast('请填写正确的手机号！')
        return
      }
      if (this.guikehuiSmsCode.interval) {
        return
      }
      this.guikehuiMember = {}
      await this.getGuikehuiMember()
      if (!this.guikehuiMember.memberId) {
        return
      }
      const member = this.guikehuiMember
      if (member.balanceUsable < this.payInfo.real_fee) {
        this.$dialog.alert({
          title: '余额不足',
          message: `当前会员可用余额不足(&yen;${member.balanceUsable})\n请更换其他会员账号`
        }).then(() => {

        })
        return
      }
      this.guigehuiSendSms()
    },
    guikehuiSmsDowntime() {
      if (this.guikehuiSmsCode.interval) return
      this.guikehuiSmsCode.interval = setInterval(() => {
        if (this.guikehuiSmsCode.downtime-- <= 0) {
          clearInterval(this.guikehuiSmsCode.interval)
          this.guikehuiSmsCode.interval = null
          this.guikehuiSmsCode.downtime = 60
        }
      }, 1000)
    },
    async getGuikehuiMember() {
      this.$toast.loading({ forbidClick: true, message: '会员信息查询中...' })
      await this.$api.pay.guikehuiQueryMember(this.guikehuiForm.mobile).then(res => {
        this.$toast.clear()
        this.guikehuiMember = res.data
      }).catch(() => {
        this.$toast.clear()
      })
    },
    guigehuiSendSms() {
      const member = this.guikehuiMember
      this.$dialog.confirm({
        title: '会员余额',
        message: `当前会员可用余额(&yen;${member.balanceUsable})`
      }).then(res => {
        this.$toast.loading({ forbidClick: true, message: '发送中...' })
        this.$api.pay.guikehuiSendSms(this.guikehuiForm.mobile).then(res => {
          this.$toast('获取成功')
          this.$toast.clear()
          this.guikehuiForm.verifyNo = res.data
          --this.guikehuiSmsCode.downtime
          this.guikehuiSmsDowntime()
        }).catch(() => { this.$toast.clear() })
      })
    },
    async update() {
      await this.$api.pay.update(this.payId).then(res => {
        console.log('-->update success')
      })
    },
    getInfo() {
      this.$toast.loading({ forbidClick: true, message: '数据加载中...' })
      this.$api.pay.info(this.payId).then(res => {
        this.pageLoading = false
        this.$toast.clear()
        this.payInfo = res.data.pay
        // 已经支付成功
        if (+this.payInfo.status === 0) {
          this.settings = res.data.settings
          this.setPayType()
        } else {
          this.$dialog.alert({
            message: +this.payInfo.status === 1 ? '支付成功' : ''
          }).then(() => {
            this.handleRedirect()
          })
        }
      }).catch(err => {
        console.log('-->data', err)
      })
    },
    setPayType() {
      const arr = []
      if (this.isWechat) {
        this.payType = 'wx_jsapi'
        arr.push({
          name: '微信支付',
          value: 'wx_jsapi',
          icon: 'wechat-pay',
          color: '#0AA650',
          disabled: false
        })
      }
      if (this.isAlipay) {
        this.payType = 'alipay_jsapi'
        arr.push({
          name: '支付宝支付',
          value: 'alipay_jsapi',
          icon: 'alipay',
          color: '#1296DB',
          disabled: false
        })
      }
      if (!this.disabledOverage && this.payInfo.user.member) {
        arr.push({
          name: `余额支付(&yen;${this.payInfo.user.member.overage})`,
          value: 'overage',
          icon: 'gold-coin',
          color: '#A06E3A',
          disabled: this.payInfo.user.member.overage <= 0
        })
      }
      if (this.settings.show_authpay) {
        arr.push({
          name: `授权码支付`,
          value: 'authpay',
          icon: 'lock',
          color: '#353b48',
          disabled: this.payInfo.user.member.overage <= 0
        })
      }
      if (this.payInfo.is_guikehui) {
        arr.push({
          name: `贵客荟储值支付`,
          value: 'guikehui',
          icon: 'vip-card-o',
          color: '#0BAAB0'
        })
      }
      this.payTypes = arr
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background: #f5f5f5;
  height: 100%;
  min-height: 100vh;
  .pay-info{
    background: #fff;
    height: 25vh;

    &__item{
      font-size: 1rem;
      line-height: 1.15;
      color: #888888;
    }
    &__price{
      font-size: 2.45rem;
      line-height: 1.45;
      color: #333;
    }
    &__store{
      font-size: 1rem;
      color: #888888;
      height: 1.1rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
  }

  .pay-types{
    margin-top: 1.2rem;
    .van-cell{
      text-align: left;
    }
    .pay-type{
      &__title{
        margin-left: .3rem;
        font-size: 1rem;
      }
    }
  }

  .btn-group{
    margin-top: 1.2rem;
    padding: 0 .8rem;
  }
}
</style>
