/**
 * Created by JianJia.Zhou<jianjia.zhou> on 2023/12/08.
 */
import { Dialog } from 'vant'
import alipay from './alipayjsapi.min.js'
const AlipayAPI = {
  PAY_SUCCESS: 9000,
  PAY_HANDLE: 8000,
  PAY_FAIL: 4000,
  PAY_CANCEL: 6001,
  PAY_NETWORK_ERROR: 6002,
  PAY_PASSWORD: 99, // ONLY IOS
  payment(config, successCallback, cancelCallback, errorCallback) {
    config = typeof config === 'string' ? JSON.parse(config) : config
    console.log('-->data', config)
    alipay.tradePay(config, res => {
      let error = ''
      switch (+res.resultCode) {
        case this.PAY_SUCCESS:
          typeof successCallback === 'function' && successCallback()
          break
        case this.PAY_HANDLE:
          error = '订单处理中'
          break
        case this.PAY_FAIL:
          error = '支付失败'
          break
        case this.PAY_PASSWORD:
        case this.PAY_CANCEL:
          typeof cancelCallback === 'function' && cancelCallback(res)
          // error = '用户取消支付'
          break
        case this.PAY_NETWORK_ERROR:
          error = '网络异常，请重试'
          break
        default:
      }
      if (error) {
        typeof errorCallback === 'function' && errorCallback(error, res)
        Dialog.alert({ title: '提示', message: error }).then(() => {})
      }
      //
    })
  },
  closeWindow() {
    alipay.popWindow()
  }
}

export default AlipayAPI
