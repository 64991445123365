import { Dialog } from 'vant'
const wechatAPI = {
  PAY_SUCCESS: 'get_brand_wcpay_request:ok',
  PAY_CANCEL: 'get_brand_wcpay_request:cancel',
  PAY_FAIL: 'get_brand_wcpay_request:fail',
  payment(config, successCallback, cancelCallback, errorCallback) {
    config = typeof config === 'string' ? JSON.parse(config) : config
    console.log('-->data', config)
    let error = ''
    // eslint-disable-next-line no-undef
    WeixinJSBridge.invoke('getBrandWCPayRequest', config, res => {
      if (res.err_msg === this.PAY_SUCCESS) {
        typeof successCallback === 'function' && successCallback(res)
      } else if (res.err_msg === this.PAY_CANCEL) {
        typeof cancelCallback === 'function' && cancelCallback()
        // error = '用户取消支付'
      } else if (res.err_msg === this.PAY_FAIL) {
        error = '网络异常，请重试'
      } else {
        error = JSON.stringify(res)
      }
      if (error) {
        typeof errorCallback === 'function' && errorCallback(error, res)
        Dialog.alert({ title: '提示', message: error }).then(() => {})
      }
    })
  },
  closeWindow() {
    // eslint-disable-next-line no-undef
    WeixinJSBridge.call('closeWindow')
  }
}

export default wechatAPI
