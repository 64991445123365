<template>
  <div class="layout-view">
    <template v-if="isInsideApp && type !== 'home'">
      <slot />
    </template>
    <template v-else>
      <slot name="error">
        <header>
          <div
            class="container"
            flex="main:left cross:center"
          >
            <div
              class="logo"
              flex="main:left cross:center"
            />
            <div class="text">收银台</div>
          </div>
        </header>
        <div class="layout-view__error">
          <van-empty
            image="network"
            :description="description"
          >
            <span
              slot="description"
              class="description"
            >{{ description }}</span>
          </van-empty>
        </div>
      </slot>
    </template>
  </div>
</template>
<script>
const IS_WECHAT = (/micromessenger/i).test(navigator.userAgent)
const IS_ALIPAY = (/AlipayClient/i).test(navigator.userAgent)
const IS_UNIONPAY = (/UnionPay/i).test(navigator.userAgent)
const IS_LOCAL = process.env.VUE_APP_LOCAL === 'local'
export default {
  name: 'LayoutView',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isWechat: IS_WECHAT,
      isAlipay: IS_ALIPAY,
      isUnionPay: IS_UNIONPAY
    }
  },
  computed: {
    isInsideApp() {
      return this.isWechat || this.isAlipay || this.isUnionPay || process.env.NODE_ENV === 'development' || IS_LOCAL
    },
    description() {
      if (this.type === 'home') {
        return '无订单信息，无法发起支付'
      }
      return '请在微信或支付宝内打开'
    }
  }
}
</script>

<style lang="scss" scoped>
  .layout-view{
    &__error{
      background: #fff;
      padding-top: 20vh;
    }
    .container{
      width: 1180px;
      margin: 0 auto;
    }
    header{
      background: #f5f5f5;
      height: 80px;
      .container{
        height: inherit;
      }
      .logo{
        background: url("@/assets/logo-big.png") no-repeat center;
        background-size: 140px 60px;
        width: 180px;
        height: inherit;
        position: relative;
        &:after{
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          height: 24px;
          width: 1px;
          background: #ccc;
          transform: translateY(-50%)
        }
        span{
          font-size: 20px;
          margin-left: 10px;
        }
      }
      .text{
        font-size: 16px;
        margin-left: 10px;
        color: #333;
      }
    }

    .description{
      color: #888;
      font-size: 18px;
      margin-top: 18px;
      display: inline-block;
    }

    @media screen and (max-width: 720px){
      header{
        height: 48px !important;
        .container{
          width: 100%;
        }
        .logo{
          background-size: 76px 28px !important;
          width: 100px !important;
          &:after{
            height: 16px !important;
          }
        }
        .text{
          font-size: 14px !important;
        }
      }
      .description{
        font-size: 14px !important;
      }
    }
  }
</style>
