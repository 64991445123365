import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/Index.vue'
import PaymentView from '../views/Payment.vue'
import AuthorizeView from '../views/Authorize.vue'
import LoginView from '../views/Login.vue'
import MchView from '../views/Mch.vue'
import ErrorView from '../views/Error.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: IndexView,
    meta: {
      title: '多多收银'
    }
  },
  {
    path: '/payment/:id',
    name: 'Payment',
    component: PaymentView,
    meta: {
      title: '收银台'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      title: '授权登录'
    }
  },
  {
    path: '/authorize',
    name: 'Authorize',
    component: AuthorizeView,
    meta: {
      title: '授权登录'
    }
  },
  {
    path: '/payment/mch/:id',
    name: 'Mch',
    component: MchView,
    meta: {
      title: '商户收款'
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorView,
    meta: {
      title: '错误'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '多多收银台'
  next()
})

export default router
