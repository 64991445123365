<template>
  <div class="error">
    <van-empty
      image="network"
      :description="description"
    />
  </div>
</template>

<script>
export default {
  name: 'ErrorView',
  data() {
    return {
      description: '网络错误'
    }
  },
  computed: {},
  created() {
    const options = this.$route.query
    if (options.error) {
      this.description = options.error
    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  padding-top: 20vh;
}
</style>
