/**
 * Created by JianJia.Zhou<jianjia.zhou> on 2023/11/22.
 */
import pay from './pay'
import user from './user'
import store from './store'
import order from './order'
import common from './common'

const api = {
  pay,
  user,
  store,
  order,
  common
}
export default api
