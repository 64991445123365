<template>
  <layout-view
    class="index-view"
    type="home"
  />
</template>

<script>
import LayoutView from '@/components/Layout'
const IS_WECHAT = (/micromessenger/i).test(navigator.userAgent)
const IS_ALIPAY = (/AlipayClient/i).test(navigator.userAgent)
export default {
  name: 'IndexView',
  components: { LayoutView },
  data() {
    return {
      isWechat: IS_WECHAT,
      isAlipay: IS_ALIPAY
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
