/**
 * Created by JianJia.Zhou<jianjia.zhou> on 2023/11/22.
 */
import request from '@/utils/request'

export function info(id) {
  return request({
    url: `/payment/store/${id}/info`,
    method: 'get'
  })
}

export default {
  info
}
