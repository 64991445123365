import request from '@/utils/request'

/**
 * 用户取消支付
 */
export function cancelBroadcast(data) {
  return request({
    url: `/device/broadcast`,
    method: 'post',
    data
  })
}

export default {
  cancelBroadcast
}
